import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination, PostFeed } from '../components/common'
import { MetaData } from '../components/common/meta'
import GokartGmaps from '../components/tracks/GokartGmaps'
import racingImage from '../images/racing-1600x900.jpg'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
  const geo = null
  const indexTitle = "Go Karting, and Race Tracks Near You"
  const indexDesc = "Find go kart and car tracks for all your favorite races in your city. Join races with Open Racer."

    return (
        <>
            <MetaData 
                location={location} 
                title={geo ? `${geo.city} Go Karting Tracks and Racing Lines` : indexTitle}
                description={geo ? `Find go karting tracks near you. Motorsport tracks and events in ${geo.city}, ${geo.country}.` : indexDesc}
                image={racingImage}
                />
            <Layout>
                    <GokartGmaps geo={geo}/>
            </Layout>
        </>
    )
}

export default Index
