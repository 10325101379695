import React, { useEffect } from "react"
import useScript from "react-script-hook"
import { loadGoKartAndRacingTracks } from "./tracks"


const TracksJS = ({city}) => {
    const [momentLoading, momentError] = useScript({
        src: "https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js",
    })
    const [mapsLoading, mapsError] = useScript({
        src: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GMAPS_API_KEY}&libraries=places&v=3.41`,
    })
    const [eventLoading, eventError] = useScript({
        src: "https://cdnjs.cloudflare.com/ajax/libs/EventEmitter/5.2.8/EventEmitter.min.js",
    })
    const [lazyLoading, lazyError] = useScript({
        src: "https://cdn.jsdelivr.net/npm/vanilla-lazyload@12.4.0/dist/lazyload.min.js",
    })
    const [geoLibLoading, geoLibError] = useScript({
        src: "/assets/js/geolib.js",
    })

    useEffect(() => {
        if (
            mapsLoading ||
            eventLoading ||
            lazyLoading ||
            geoLibLoading
        ) return

        if(city) {
            const { lat, lng, country, city_ascii } = city
            sessionStorage.setItem('latitude', lat);
            sessionStorage.setItem('longitude', lng);
            sessionStorage.setItem('locationname', `${city_ascii}, ${country}`);
            sessionStorage.setItem('city', city_ascii);
        }
        loadGoKartAndRacingTracks(process.env.GATSBY_GMAPS_API_KEY)
    }, [
        momentLoading,
        mapsLoading,
        eventLoading,
        lazyLoading,
        geoLibLoading,
    ])

    return <></>
}

export default TracksJS
