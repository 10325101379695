import React from "react"
import TracksJS from "./TracksJS"
import { ClientOnly, SvgGradient } from "../common"

const Gokart = ({
    city,
    hideGoKart = false,
}) => {
    return (
        <>
            {!hideGoKart && (
                <>
                    <h1>
                        Suggest an edit
                    </h1>
                    <p>
                        Please help us, and select karting
                        tracks by clicking on the check icon. We will add the
                        place to our DB 🏁
                    </p>
                    <p className="hidden">
                        <a href="#" className="filter-btn active">
                            Outdoor
                        </a>
                        <a href="#" className="filter-btn active">
                            Indoor
                        </a>
                    </p>
                </>
            )}
            <div
                className={!hideGoKart ? "post-feed horizontal-feed" : "hidden"}
                id="gokart-feed"
            >
                {Array.from(Array(10)).map((_, i) => (
                    <article className="post-card track-card" key={i}></article>
                ))}
            </div>
            <div
                id="gokart-map"
                style={{ height: "400px" }}
                className={hideGoKart ? "hidden" : undefined}
            ></div>
             
            <ClientOnly>
                <TracksJS city={city} />
            </ClientOnly>
        </>
    )
}

export default Gokart
