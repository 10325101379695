export function loadGoKartAndRacingTracks(apiKey) {
/*!
 * Position Library
 */
var Pos = (function () {

	'use strict';

	//
	// Variables
	//

    let publicAPIs = {};
    let eventEmitter;
    const posChangedEvent = "PosChanged";

    /**
	 * Promise wrapper for HTML5 geoposition
	 */
    let getGeoPosition = function (options = { maximumAge: 10 * 60 * 1000, 
                                                timeout: 10 * 1000}) {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    };

    const detectPos = async function () {
        let pos = {};

        if(Pos.isGeoLocation()) {
            try {
                const position = await getGeoPosition();
                pos["latitude"] = position.coords.latitude;
                pos["longitude"] = position.coords.longitude;
            } catch (err) {
                switch(err.code)
                {
                    case err.TIMEOUT:
                        console.log("The request to get user location has aborted as it has taken too long.");
                        break;
                    case err.POSITION_UNAVAILABLE:
                        console.log("Location information is not available.");
                        break;
                    case err.PERMISSION_DENIED:
                    console.log("Permission to share location information has been denied!");
                        break;
                        default:
                    console.log("An unknown error occurred.");
                }
            }
            
        };
        
        if (!pos["latitude"]) {
            const geoDataFromIpResponse = await fetch("https://or.ghe.workers.dev")
            const { latitude, longitude } = await geoDataFromIpResponse.json()

            pos["latitude"] = latitude;
            pos["longitude"] = longitude;
        }
    
        savePos(pos["latitude"], pos["longitude"]);
    
        return pos;
    }

	/**
     * 1) Gets the coordinates from sessionStorage first (if saved from a previous session). 
     * 2) If Storage empty and geolocation setting is enabled, load current live geoposition. 
     * 3) Get the IP address position as a fallback.
     * 
	 * @return  object  Returns an object coordinate (e.g. {latitude: 51.5023, longitude: 7.3815})
	 */
	publicAPIs.getPos = async function () {
        const pos = openPos();
        if(pos["latitude"] && pos["longitude"]) return pos;
        
		return await detectPos();
    };

    /**
     * Set the latitude and longitude
     * 
	 * @param  float    latitude
     * @param  float    longitude
	 */
	publicAPIs.setPos = async function (lat, lng) {
        publicAPIs.resetPos();
        savePos(lat, lng);
        if(!eventEmitter) eventEmitter = new EventEmitter();
        eventEmitter.emit(posChangedEvent);
    };

    /**
     * Save the latitude and longitude for the Browser session
     * 
	 * @param  float    latitude
     * @param  float    longitude
	 */
	const savePos = function (lat, lng) {
		sessionStorage.setItem('latitude', lat);
        sessionStorage.setItem('longitude', lng);
    };

    /**
     * Open the latitude and longitude for the Browser session
     * 
	 */
	const openPos = function () {
        const localstorage = localStorage.getItem('latLng')
        const latLnt = JSON.parse(localstorage)

        if(latLnt?.lat && latLnt?.lng) {
            return {
                latitude: latLnt.lat,
                longitude: latLnt.lng
            }
        }

		return {
            latitude: sessionStorage.getItem('latitude'),
            longitude: sessionStorage.getItem('longitude')
        };
    };
    
    /**
	 *  Resets the position fields (city, lat, lng,...)
	 */
    publicAPIs.resetPos = function () {
		sessionStorage.removeItem('latitude');
        sessionStorage.removeItem('longitude');
        sessionStorage.removeItem('locationname');
        sessionStorage.removeItem('city');
    };

    /**
	 *  @param  string  city name
	 */
    publicAPIs.setCity = function (city) {
		sessionStorage.setItem('city', city);
    };

    /**
	 *  Enable HTML5 Geolocation GPS position
	 */
    publicAPIs.activateGeoLocation = async function () {
        publicAPIs.resetPos();
        localStorage.setItem('geolocation', true);
        await publicAPIs.getPos();
        if(!eventEmitter) eventEmitter = new EventEmitter();
        eventEmitter.emit(posChangedEvent);
    };

    /**
     * Check if HTML5 Geolocation GPS position setting is set
     * 
	 * @return  boolean 
	 */
    publicAPIs.isGeoLocation = function () {
		return localStorage.getItem('geolocation');
    };

    /**
     * Calculates distance from current position by latitude/longitude
     *
     * @param    object    Destination position {latitude: 123, longitude: 123}
     * @return   integer   Distance (in meters)
     */
    publicAPIs.getDistance = async function (dest) {
		return geolib.getDistance(await publicAPIs.getPos(), dest, undefined, undefined);
    };

     /**
     * Formats the results of gmaps geocode results
     * Source: https://github.com/onury/geolocator/blob/master/src/core/geo.helper.js
     * 
	 * @return  formatted address 
	 */
    publicAPIs.formatAddressComponents = function (address_components) {
        if (!Array.isArray(address_components) || address_components.length <= 0) {
            return null;
        }

        let i, c,
            o = {},
            comps = address_components;

        for (i = 0; i < comps.length; i += 1) {
            c = comps[i];
            if (c.types && c.types.length > 0) {
                o[c.types[0]] = c.long_name;
                o[c.types[0] + '_s'] = c.short_name;
            }
        }
        return {
           commonName: o.point_of_interest
                || o.premise
                || o.subpremise
                || o.colloquial_area
                || '',
            streetNumber: o.street_number || '',
            street: o.administrative_area_level_4
                || o.administrative_area_level_3
                || o.route
                || '',
            route: o.route || '',
            neighborhood: o.neighborhood
                || o.administrative_area_level_5
                || o.administrative_area_level_4
                || '',
            town: o.sublocality || o.administrative_area_level_2 || '',
            city: o.locality || o.administrative_area_level_1 || '',
            region: o.administrative_area_level_2
                || o.administrative_area_level_1
                || '',
            postalCode: o.postal_code || '',
            state: o.administrative_area_level_1 || '',
            stateCode: o.administrative_area_level_1_s || '',
            country: o.country || '',
            countryCode: o.country_s || ''
        };
    }

    /**
     * Calls function when position changed (lat/lng)
     *
     * @param    function    Callback function
     */
    publicAPIs.onPosChange = function (callback) {
        if(!eventEmitter) eventEmitter = new EventEmitter();
		eventEmitter.addListener(posChangedEvent, callback);
    };

	return publicAPIs;

})();

var Tracks = (function () {

async function lookUpAddress(geocoder, position) {
    return new Promise((resolve, reject) => {
        geocoder.geocode(
            {'location': new google.maps.LatLng(position.latitude, position.longitude)}, 
            (results, status) => {
                if (status !== 'OK') {
                    reject(new Error('Geocoder failed due to: ' + status));
                    return;
                }

                if (!results[0]) {
                    reject(new Error('No results found'));
                    return;
                }

                const address = Pos.formatAddressComponents(results[0].address_components);
                console.log('looked up address of position', address, position)
                resolve(address);
            });
    });
};

// Select searchbox textcontent on focus
let search = document.getElementById("search-input");
let lastSelected = false;

if(search) {
    search.addEventListener("click", function() { 
        if(!lastSelected) {
            this.select();
            this.setSelectionRange(0, 9999); //iOS devices
            lastSelected = true;
        } 
    });
    search.addEventListener("focusout", function() {
        lastSelected = false;
    });
    
    // Seach form submit action 
    // Pick first result on form submit
    let pac_input = document.getElementById('search-input');
    
    (function pacSelectFirst(input) {
        // store the original event binding function
        var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
    
        function addEventListenerWrapper(type, listener) {
            // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
            // and then trigger the original listener.
            if (type == "keydown") {
                var orig_listener = listener;
                listener = function(event) {
                    var selectedItem = document.querySelector(".pac-item-selected")
                    var suggestion_selected = selectedItem && selectedItem.length > 0;
                    if (event.which == 13 && !suggestion_selected) {
                        var simulated_downarrow = new KeyboardEvent("keydown", {keyCode: 40, which: 40});
                        orig_listener.apply(input, [simulated_downarrow]);
                    }
    
                    orig_listener.apply(input, [event]);
                };
            }
    
            _addEventListener.apply(input, [type, listener]);
        }
    
        input.addEventListener = addEventListenerWrapper;
        input.attachEvent = addEventListenerWrapper;
    })(pac_input);
    
}



function emptyFeeds() {
    emptyFeedbyId('gokart-feed');
    emptyFeedbyId('track-feed');
    emptyFeedbyId('public-races-feed');
    lastMapBounds = null;
}

function emptyFeedbyId(id) {
    let tracks = document.querySelectorAll('#' + id + ' article');

    tracks.forEach((article) => { article.innerHTML =  ""});
}


function fillTrackFeed(sortedTracks, places) {
    const trackFeed = document.getElementById('track-feed');
    const tracks = trackFeed.querySelectorAll('article');

    tracks.forEach((elem, index) => {
        const track = sortedTracks[index];
        const place = places.find((p) => p.trackgroup == track.trackgroup);
        const rating = place.place_rating;
        elem.innerHTML =  
                `<a class="post-card-image-link track-card-image" href="${track.url}">
                <svg class="stroke-fill-light" height="100%" width="100%" >
                    <use filter="url(#dropshadow)" xlink:href="/${track.svg}#track" ></use>
                </svg>
            </a>
            <div class="post-card-content track-card-content">
                <a class="post-card-content-link" href="${track.url}">
                    <header class="post-card-header track-card-header">
                        <h2 class="post-card-title track-card-title">${track.trackgroup}</h2>
                        <span class="post-card-tags track-card-tags">
                        ${generateRatingHTML(rating)} ${rating || ""}
                        · ${Math.round(track.distance/1000)}km
                        · ${track.countryname}</span>
                    </header>
                </a>
                <footer class="post-card-meta track-card-meta">
                    <span class="post-card-author">
                    </span>
            </footer>
            </div>`;
    });
}

async function loadNearbyTracks() {
    let response = await fetch("/assets/tracks.json");
    let tracks = await response.json();
    let tracksArr = Array.from(tracks);

    // group by trackgroup and map first object to array
    const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    let tracksGrouped = groupBy(tracksArr, 'trackgroup');
    let tracksFiltered = [];
    Object.values(tracksGrouped).forEach((value) => { tracksFiltered.push(value[0]) });
    
    let tracksSorted = geolib.orderByDistance(await Pos.getPos(), tracksFiltered);
    const places = await loadPlacesforTracks();
    fillTrackFeed(tracksSorted, places);
}

async function loadPlacesforTracks() {
    const response = await fetch("/assets/places.json");
    const places = await response.json();
    return Array.from(places);
}

async function updateSearchbox(value) {
    let input = document.getElementById('search-input');
    if(!input) return;

    input.value = value;
    
    // Show search icon for cities and navigation icon for Geolocation option
    // let searchIcon = document.querySelector(".search-form .feather use");
    // let icon = Pos.isGeoLocation() ? "navigation" : "search";
    // searchIcon.setAttribute("xlink:href", "{{ site.baseurl }}assets/images/feather-sprite.svg#" + icon);
}


let lastMapBounds;

async function createMap(map, markers, lazyLoad) {
    let {latitude, longitude} = await Pos.getPos();
    let pos = new google.maps.LatLng(latitude, longitude);
    
    map.setZoom(9);
    map.setCenter(pos);

    const placesService = new google.maps.places.PlacesService(map);
    const geocoder = new google.maps.Geocoder();
    let mapLoaded; 
    map.addListener('idle', async function() {
        if(!lastMapBounds || lastMapBounds.equals(map.getBounds())) {
            if(!mapLoaded) {
                lastMapBounds = map.getBounds()
                mapLoaded = true
                const places = await loadGoKartWithinDistance(
                    placesService,
                    filterSet,
                    pos,
                    ""
                )
                updateGoKartDom(places, map, markers, lazyLoad)
            }
            return
        }

        emptyFeedbyId('gokart-feed');
        const places = await loadGoKartWithinBounds(placesService, filterSet, map.getBounds());
        // const places = await loadGoKartWithinDistance(placesService, filterSet, pos, '');
        updateGoKartDom(places, map, markers, lazyLoad)
    });

    //
    // Create Autocomplete Dropdown
    //
    var input = document.getElementById('search-input');

    // Filter autocomplete results to cities only 
    // and bias the results to 100km around the pos
    var autocomplete = new google.maps.places.Autocomplete(input, {
            types: ['(regions)'],
            bounds: new google.maps.Circle({
                center: pos,
                radius: 100000
            }).getBounds()
    });

    // Set the data fields to return when the user selects a place.
    autocomplete.setFields(
            ['address_components', 'geometry', 'name']);
    
    autocomplete.addListener('place_changed', async function() {
        var place = autocomplete.getPlace();
        if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }
        console.log('placechanged: %f, %f', place.geometry.location.lat(), place.geometry.location.lng())

        // Empty track feeds 
        emptyFeeds();
        // Reload results based on new position
        let address = Pos.formatAddressComponents(place.address_components);
        Pos.setPos(place.geometry.location.lat(), place.geometry.location.lng());
        storeLocationName(sessionStorage, formatLocation(address))
        storeCity(sessionStorage, address.city)

        const places = await loadGoKartWithinDistance(placesService, filterSet, place.geometry.location, address.city);
        fitPlacesBounds(map, places, place.geometry.location)
        updateGoKartDom(places, map, markers, lazyLoad)
        updateCityDom(address.city);
        loadNearbyTracks(placesService);
    });

    // Insert custom autcomplete element to show 
    // const checkForAutocomplete = setInterval(function(){
    //     const pacContainer = document.querySelector(".pac-container");

    //     if(pacContainer) {
    //         pacContainer.insertAdjacentHTML('afterbegin', 
    //                 `<div id="use-current-location" class="pac-item location">
    //                     <span class="pac-icon"></span>
    //                     <span class="pac-item-query">Current Location</span> 
    //                 </div>`);
    //         let onCurrentLocation = async function() {
    //                 emptyFeeds();
    //                 await Pos.activateGeoLocation();
    //                 const pos = await Pos.getPos();
    //                 const address = await lookUpAddressWithCache(sessionStorage, geocoder, pos)
    //                 updateSearchbox(address.name);
    //                 loadNearbyTracks();
    //                 const places = await loadGoKartWithinDistance(placesService, filterSet, new google.maps.LatLng(pos.latitude, pos.longitude), address.city);
    //                 fitPlacesBounds(map, places, new google.maps.LatLng(pos.latitude, pos.longitude))
    //                 updateGoKartDom(places, map, markers, lazyLoad)
    //                 updateCityDom(address.city)
    //         };
            
    //         document.getElementById("use-current-location").addEventListener('mousedown', onCurrentLocation);
    //         clearInterval(checkForAutocomplete);
    //     }
    // }, 500);

    const address = await lookUpAddressWithCache(sessionStorage, geocoder, {latitude, longitude})
    updateSearchbox(address.name);
    const places = await loadGoKartWithinDistance(placesService, filterSet, pos, address.city);
    fitPlacesBounds(map, places, pos)
    updateGoKartDom(places, map, markers, lazyLoad)
    updateCityDom(address.city)

    async function lookUpAddressWithCache(storage, geocoder, position) {
        let city = getCityFromStorage(storage)
        let name = storage.getItem('locationname')
        if(!city) {
            // cache miss
            const address = await lookUpAddress(geocoder, position)
            city = address.city
            storage.setItem('city', city)
            storeCity(storage, city)
            name = formatLocation(address)
            storeLocationName(storage, name)
        }

        return {city, name}
    }

    function storeCity(storage, city) {
        storage.setItem('city', city)
    }

    function storeLocationName(storage, locationName) {
        storage.setItem('locationname', locationName);
    }

    function formatLocation(address) {
        return address.city + ', ' + address.country
    }
};

function getCityFromStorage(storage) {
    return storage.getItem('city')
}

let filterSet = new Set();

let GMAPSAPIKEY
function init(apiKey) {
    GMAPSAPIKEY = apiKey
    // Load when track feed found on website
    if(document.getElementById('track-feed')){
        loadNearbyTracks();
    }
    
    const gokartMap = document.getElementById('gokart-map');
    // Load public races
    if(document.getElementById('public-races-feed') && !gokartMap){
        fillPublicEvents()
    }

    if(!gokartMap) return
    // Create the map
    const map = new google.maps.Map(gokartMap, {
        zoom: 17,
        styles: [{"stylers": [{ "saturation": -80 }]}]
    });

    const lazyLoad = new LazyLoad({
        elements_selector: ".lazy",
        container: document.getElementById('gokart-feed')
    });

    const markers = []
    createMap(map, markers, lazyLoad);

    // Load filters and add event listener to all filter buttons
    const filterButtons = document.querySelectorAll(".filter-btn");
    filterButtons.forEach((elem) => {
        if(elem.classList.contains('active')) filterSet.add(elem.textContent);
        elem.addEventListener("click", onFilterClick)
    });

    function onFilterClick(event) {
        event.target.classList.toggle("active");
        const placesService = new google.maps.places.PlacesService(map);
        toggleFilter(placesService, event.target.textContent);
        event.preventDefault();

        // Add information message for users
        // if(!document.getElementById('help-us')) {
        //     document.querySelector('#gokart-feed').insertAdjacentHTML('beforebegin', 
        //         `<blockquote id="help-us">
        //             <p>🏁 Help us to build the biggest database by selecting the checkboxes to submit your data.</p>
        //         </blockquote>`);
        // }
    }

    async function toggleFilter(placesService, filter) {
        if(!filterSet.delete(filter)) filterSet.add(filter);
    
        emptyFeedbyId('gokart-feed');
        const places = await loadGoKartWithinBounds(placesService, filterSet, map.getBounds());
        updateGoKartDom(places, map, markers, lazyLoad)
    }
};

function goKartQueryWithProperties(propertySet) {
    return "gokart " + [...propertySet].join(" ").replace("Outdoor","");
}

function keyword() {
    return 'gokart track';
}

function searchPlaces(placesService, options) {
    return new Promise((resolve, reject) => {
        placesService.nearbySearch(options, function(results, status, pagination) {
            if (status !== 'OK') {
                console.error('place search failed: ', status);
                reject(status);
                return;
            }
            // Remove permanently closed places
            const notClosed = results.filter(item => !item.permanently_closed);
            if(window.gtag) gtag('event', 'searchnearby', {
                'event_category': 'Google Places',
                'event_label': `${JSON.stringify(options)}`,
                'value': notClosed.length
              });
            resolve(notClosed);
        });
    });
}

function fitPlacesBounds(map, places, latLngCenter) {
    const bounds = new google.maps.LatLngBounds(latLngCenter);
    places.forEach(p => bounds.extend(p.geometry.location))
    map.fitBounds(bounds);
    lastMapBounds = map.getBounds();
}

function updateGoKartDom(places, map, markers, lazyLoad) {
    markers.forEach(e => e.setMap(null))
    markers.length = 0

    places.forEach(p => document.createElement('li').textContent = p.name)
    const markersNew = places.map(p => new google.maps.Marker({
        map: map,
        title: p.name,
        position: p.geometry.location
    }))
    markersNew.forEach(e => markers.push(e))

    fillGokartFeed(places, markersNew);
    fillPublicEvents();
    lazyLoad.update();
}

async function loadGoKartWithinBounds(placesService, filters, bounds) {
    let places = [];
    // if(filterSet.has("Outdoor")) places = places.concat(await loadGoKartTracksOutdoorWithinBounds(bounds));
    if(filterSet.has("Indoor")) places = places.concat(await searchGoKartPlacesWithinBounds(placesService, filters, bounds));
    const placesWithDistance = await addDistance(places);
    return cleanupAndSortPlaces(placesWithDistance);
}

async function searchGoKartPlacesWithinBounds(placesService, filters, bounds) {
    const options = {bounds, name: goKartQueryWithProperties(filters), keyword: keyword()};

    try {
        const foundPlaces = await searchPlaces(placesService, options);
        console.log("searched go kart places within bounds: ", bounds, foundPlaces);
        return foundPlaces;
    } catch(error) {
        console.log("error on search for go kart places within bounds: ", bounds, error);
        return [];
    }
    
}

async function loadGoKartWithinDistance(placesService, filters, latLng, cityName) {
    let places = [];
    // if(filterSet.has("Outdoor")) places = places.concat(await loadGoKartTracksOutdoorWithinDistance(latLng));
    if(filterSet.has("Indoor")) places = places.concat(await searchGoKartPlacesWithinDistance(placesService, filters, latLng, cityName));
    return cleanupAndSortPlaces(places);
}

function cleanupAndSortPlaces(places) {
    const placesWithoutId = places.filter(p => !p.place_id)
    const placesUniqueId = places.filter(p => p.place_id).filter((set => f => !set.has(f.place_id) && set.add(f.place_id))(new Set));
    return placesUniqueId.concat(placesWithoutId).sort((a, b) => a.distance - b.distance)
}

async function addDistance(places) {
    const placesPromise = places.map(async (place) => {
        const distance = place.distance || await Pos.getDistance({latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng()})
        Object.assign(place, {distance: +distance})
        return place
    })
    return await Promise.all(placesPromise);
}

async function searchGoKartPlacesWithinDistance(placesService, filters, latLng, cityName) {
    const distance = 80000
    const name = goKartQueryWithProperties(filters) + " " + cityName;
    const options = {location: latLng, radius: distance, name, keyword: keyword()};
    const events = await searchPlaces(placesService, options);
    console.log("searched go kart places within distance: ", distance, events);
    return events;
}

async function loadGoKartTracksOutdoorWithinDistance(latLng) {
    const allOutdoorTracks = await loadOutdoorTracks();
    const allOutdoorTracksFormatted = formatPlaces(allOutdoorTracks);
    const tracksSorted = geolib.orderByDistance({latitude: latLng.lat(), longitude: latLng.lng()}, allOutdoorTracksFormatted);
    return tracksSorted.slice(0, 10); 
}

async function loadGoKartTracksOutdoorWithinBounds(bounds) {
    const allOutdoorTracks = await loadOutdoorTracks();
    const allOutdoorTracksFormatted = formatPlaces(allOutdoorTracks);
    return allOutdoorTracksFormatted.filter(track => bounds.contains(track.geometry.location))
}

async function loadOutdoorTracks() {
    const response = await fetch("/assets/gokart-outdoor.json");
    const tracks = await response.json();
    let tracksArr = Array.from(tracks);

    return tracksArr;
}

function formatPlaces(places) {
    return places.map(place => {
        const placeExtended = {
            geometry: {
                location: new google.maps.LatLng(place.latitude, place.longitude)
            },
            rating: place.place_rating
        }
        Object.assign(placeExtended, place)
        return placeExtended
    })
}

function fillGokartFeed(places, markers) {
    let trackFeed = document.getElementById('gokart-feed');
    trackFeed.querySelectorAll('article').forEach(e => e.remove());
    places.map(e => document.createElement('article'))
            .forEach(async (e, index) => {
        e.className = "post-card track-card";
        trackFeed.appendChild(e);
        const place = places[index]

        markers[index].addListener('mouseover', function() {
            e.classList.toggle("post-card-hover");
        });
        markers[index].addListener('mouseout', function() {
            e.classList.toggle("post-card-hover");
        });
        e.onmouseenter = () => {
            markers[index].setAnimation(google.maps.Animation.BOUNCE);
        };
        e.onmouseleave = () => {
            markers[index].setAnimation();
        };
        const id = place.place_id || place.name
        // loadFilterCheckboxes(e, id);

        let url = `https://www.google.com/maps/search/?api=1&query=${place.name}&query_place_id=${place.place_id}`;
        let headerGraphic = `<div class="post-card-image" style="background-image: url('/assets/images/outdoor-struct-bg.jpg'})"></div>`;
        let target = "_blank";
        if(place.filename) {
            url = `/go-karting/${place.filename}`;
            target = "";
            headerGraphic = `<div class="post-card-image">
                                <iframe
                                    width="200%"
                                    height="160%"
                                    style="position: absolute; 
                                            width: 200%;
                                            height: 160%;
                                            top:-30%; bottom: -30%; left: -50%; right: -50%; 
                                            filter: contrast(130%) sepia(50%);"
                                    frameborder="0"
                                    class="lazy"
                                    data-src="https://www.google.com/maps/embed/v1/view?center=${place.latitude},${place.longitude}&maptype=satellite&zoom=17&key=${GMAPSAPIKEY}">  
                                </iframe>
                            </div>`;
        }
        else if(place.photos !== undefined) {
            headerGraphic = `<div class="post-card-image" style="background-image: url(${place.photos[0].getUrl()})"></div>`;
        }
        const trackCard = document.createElement('div')
        trackCard.className = 'post-card-content track-card-content" style="position:relative'
        trackCard.innerHTML =  
            `
                    <a class="post-card-image-link track-card-image" href="${url}" target="${target}">
                                    ${headerGraphic}
                    </a>        
                    <div class="post-card-content-link">
                        <header class="post-card-header track-card-header">
                            <h2 class="post-card-title track-card-title">${place.name}</h2>
                        </header>
                        <section class="post-card-excerpt">
                            ${generateRatingHTML(place.rating || 0)}
                            ${place.user_ratings_total || 0}
                            · ${Math.round(place.distance / 1000 )}km
                        </section>
                    </div>
                `;

            const trackCardFooter = document.createElement('footer')
            trackCardFooter.className = 'block h-32 empty:h-20 empty:bg-gray-100 empty:m-6 empty:animate-pulse'
            // trackCardFooter.innerHTML = `<a class="schedule-btn" href="${url}" target="${target}">Show</a>`

            const form = document.createElement('form')
            const buttonClassname = "w-full flex items-center justify-center text-base space-x-2 my-6" 
            form.className = buttonClassname
            form.innerHTML = `<input class="block rounded-xl px-2 border-2 border-indigo-800 hover:bg-indigo-300 hover:text-gray-700 bg-indigo-800 text-white cursor-pointer" href="${url}" target="${target}" type="submit" value="Mark as indoor ✓" />`
            form.onsubmit = (e) => {
                e.preventDefault()
                form.innerHTML=`<span>👏 Thanks!</span>`
                window
                    .fetch("https://or-karting-suggestion.ghe.workers.dev/", {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify({
                            name: place.name,
                            gmaps_rating: place.rating,
                            gmaps_rating_total: place.user_ratings_total,
                            location: place.geometry.location.lat() + "," + place.geometry.location.lng(),
                            place_id: place.place_id,
                            subtype: "indoor",
                        }),
                    })
                    .then((res) => res.json().then((json) => console.log(json)))
            }

            const formOutdoor = document.createElement('form')
            formOutdoor.className = buttonClassname
            formOutdoor.innerHTML = `<input class="block rounded-xl px-2 border-2 border-indigo-800 hover:bg-indigo-300 hover:text-gray-700 bg-indigo-800 text-white cursor-pointer" href="${url}" target="${target}" type="submit" value="Mark as outdoor ✓" />`
            formOutdoor.onsubmit = (e) => {
                e.preventDefault()
                formOutdoor.innerHTML=`<span>👏 Thanks!</span>`
                window
                    .fetch("https://or-karting-suggestion.ghe.workers.dev/", {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify({
                            name: place.name,
                            gmaps_rating: place.rating,
                            gmaps_rating_total: place.user_ratings_total,
                            location: place.geometry.location.lat() + "," + place.geometry.location.lng(),
                            place_id: place.place_id,
                            subtype: "outdoor",
                        }),
                    })
                    .then((res) => res.json().then((json) => console.log(json)))
            }
            
            fetchTypesenseFirstResult(place.place_id).then((track) => {
                if(track) {
                    const { subtype } = track
                    trackCardFooter.innerHTML = `<div class='p-6 text-left text-base'>🎉 Wohoo, found this track in our DB. Marked as ${subtype}.</div>`
                    return
                }

                trackCardFooter.appendChild(form)
                trackCardFooter.appendChild(formOutdoor)
            })
            trackCard.appendChild(trackCardFooter)
            e.appendChild(trackCard);
    })
}

async function fetchTypesenseFirstResult (place_id) {
    const response = await fetch(
        `${process.env.GATSBY_TYPESENSE_PROTOCOL}://${
            process.env.GATSBY_TYPESENSE_HOST
        }${
            process.env.GATSBY_TYPESENSE_PORT == "443"
                ? ""
                : `:${process.env.GATSBY_TYPESENSE_PORT}`
        }/collections/tracks/documents/search?q=${place_id}&query_by=gmaps_place_id`,
        {
            headers: {
                "X-TYPESENSE-API-KEY": `${process.env.GATSBY_TYPESENSE_READ_API}`,
            },
        }
    )
    const data = await response.json()
    return data?.hits[0]?.document
} 


function updateCityDom(city) {
    const elements = document.querySelectorAll("[data-cityname]")
    elements.forEach(e => e.innerHTML = city)
}

// Initialize Cloud Firestore through Firebase
// firebase.initializeApp({
//     apiKey: 'AIzaSyCbMu6NlBD0x4yIK4PqLSvjgBpHn4vG3OU',
//     authDomain: 'open-racer-kart-tracks.firebaseapp.com',
//     projectId: 'open-racer-kart-tracks'
// });
// var db = firebase.firestore();

async function loadFilterCheckboxes(elem, placeId) {
    if(!filterSet.size === 0) return;

    // Get filter values from db 
    const cityRef = db.collection('places').doc(placeId);
    let getPlaceDoc;
    let placeData;
    try {
        getPlaceDoc = await cityRef.get();
        if(getPlaceDoc.exists) placeData = getPlaceDoc.data(); 
    }
    catch(error) {
        console.error("Error getting document: ", error);
    }


    for(let filter of filterSet) {
        let checkedCount = 0;
        if(placeData && typeof(placeData[filter]) !== "undefined") {
            console.log('Document ' + filter + ' value:', placeData[filter]);
            checkedCount = placeData[filter];
        }

        // Create an ID for the checkbox and set for the label 
        const id = makeSafeForCSS(placeId + filter);
        elem.insertAdjacentHTML('afterbegin', 
                `<div class="post-card-meta filters">
                    <input type="checkbox" id="${id}" name="filter" value="${filter}">
                    <label for="${id}">${filter} · 👍 <span>${checkedCount}</span></label>
                </div>`);   

        // On change of the checkbox, increment or decrement the value in the db
        elem.querySelector('input').addEventListener('change', async (event) => {
            const incrementBy = event.target.checked ? 1 : -1;
            
            try {
                await db.collection("places").doc(placeId).set({
                    [filter]: firebase.firestore.FieldValue.increment(incrementBy)
                }, { merge: true });
                console.log("Document written.");

                // Update filter count
                const filterLabelSpan = document.querySelector('[for=' + id + '] span');
                filterLabelSpan.innerHTML = +filterLabelSpan.innerHTML  + incrementBy;

                // animate
                const filterElem = document.getElementById(id).closest('.filters');
                filterElem.classList.toggle('grey-bg');
                setTimeout(() => {filterElem.classList.toggle('grey-bg');}, 500);
            }
            catch(error) {
                console.error("Error adding document: ", error);
            }
        });
        
    }
}

// https://stackoverflow.com/questions/7627000/javascript-convert-string-to-safe-class-name-for-css
function makeSafeForCSS(name) {
    return name.replace(/[^a-z0-9]/g, function(s) {
        var c = s.charCodeAt(0);
        if (c == 32) return '-';
        if (c >= 65 && c <= 90) return '_' + s.toLowerCase();
        return '__' + ('000' + c.toString(16)).slice(-4);
    });
}

// Public EVENTS

// Fill public events feed
function fillPublicEvents() {
   const publicRacesFeed = document.getElementById('public-races-feed');

   if(!publicRacesFeed) return;

   const date = moment();
   publicRacesFeed.querySelectorAll('article').forEach((articleElem) => {
        // reset element
        articleElem.classList.remove('blue-border');
        delete articleElem.dataset.date;
        delete articleElem.dataset.eventId;

        // show Wednesdays and Saturdays
        const day = date.day();
        if(day < 3) { // Sun, Mon, Tue 
            //set Wednesday in current week and bubble to next week in case of Sunday
            date.set(date.day(3)); 
        }
        else if(day > 3 && day < 6) { // Thu, Fri
            // set Saturday in current week
            date.set(date.day(6)); 
        }

        articleElem.innerHTML =
            `<a class="post-card-image-link track-card-image" href="#">
                <header class="post-card-header track-card-header">
                    <span>JOIN</span>
                    <h2 class="post-card-title track-card-title">${date.format('ddd D')}</h2>
                </header>
             </a>`;

        articleElem.dataset.date = date.toISOString();
        
        fetchUserCount(articleElem, date.format('YYYY-MM-DD'));
        date.add(3, 'days')

        articleElem.addEventListener('click', onEventClick);
   });
}

async function fetchUserCount(elem, dateStr) {
    try {
        const {latitude, longitude} = await Pos.getPos();
        const response = await fetch(`{{ site.rallly_url }}/api/event/?lat=${latitude}&lng=${longitude}&dist=100000&date=${dateStr}`);
        const responseJson = await response.json();
        const events = responseJson.events;
        const cardLinkElement = elem.querySelector('a');
        const participants = events.length ? events[0].participants_total : 0;

        console.log(events);

        // TODO: sort events by participants_total

        // cardLinkElement.insertAdjacentHTML('beforeend', 
        //     `<div class="top-right">
        //         <svg class="feather"><use xlink:href="{{ site.baseurl }}assets/images/feather-sprite.svg#users"></use></svg>
        //         ${participants}
        //     </div>`);
        
        if(events.length) {
            cardLinkElement.href = generateEventLink(events[0]._id);

            elem.dataset.eventId = events[0]._id;

            // Highlight events with participants signed up
            if(participants) elem.classList.add('blue-border');
        }
        

    } catch (error) {
        console.error("Error loading user count: ", error);
    }
}

async function onEventClick(event) {
    const articleElem = event.target.closest('article');

    // Return when data-event-id is set and allow link to navigate to the event page
    if(articleElem.hasAttribute('data-event-id')) return;

    event.preventDefault();

    // Create new event and open it
    try {
        const {latitude, longitude} = await Pos.getPos();
        const isoNowDateStr = articleElem.getAttribute('data-date');
        const date = moment(isoNowDateStr)
        // storage holds the global city state
        const city = getCityFromStorage(sessionStorage);

        // Load track name from data field 
        let trackname
        var publicRacesFeed = document.getElementById('public-races-feed');
        if(publicRacesFeed.hasAttribute('data-track')) {
            trackname = publicRacesFeed.dataset.track
        }
        

        const bodyObj = {
            "creator": {
              "allowNotifications": true,
              "name": "OR",
              "email": "events@open-racer.com"
            },
            "dates": [date.format('YYYY-MM-DD')],
            "title": date.format('ddd D') + " 🏁 " + (trackname || city),
            "place": {
                "google_id": "na",
                "name": "na",
                "coord": [longitude, latitude]
            }
        };

        const response = await fetch('{{ site.rallly_url }}/api/event', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(bodyObj)
        });
        
        const result = await response.json();
        window.location.href = generateEventLink(result._id);

    } catch (error) {
        console.error("Error creating event: ", error);
    }
}

function generateEventLink(eventId) {
    return '{{ site.baseurl }}schedule.html?eid=' + eventId;
}

function generateRatingHTML(rating) {
    return `<span class="stars-container stars-${Math.round(rating * 2) * 10} ">★★★★★</span>`;
}

// Add event listener to subscribe form

    const form = document.getElementById('subscribe-form');

    if (form) {
        const email = form.querySelector('.subscribe-email');

        form.addEventListener('submit', async (event) => {
            event.preventDefault();
            let message = "Sorry. We couldn't add your email.";
            
            try {
                const {latitude, longitude} = await Pos.getPos();

                // Add or update lat/long on existing email address 
                await db.collection("subscribers").doc(email.value.toLowerCase()).set({
                    location: new firebase.firestore.GeoPoint(+latitude, +longitude)
                }, { merge: true });
                console.log("Document written.");

                // Update the message to show and hide the form
                message = "awesome. subscribed!";
                form.style.display = 'none';

                if(window.gtag) gtag('event', 'subscribe', {
                    'event_category': 'Email Subscription',
                    'event_label': `${latitude}, ${longitude}`,
                  });
            }
            catch(error) {
                console.error("Error adding document: ", error);
            }

            form.insertAdjacentHTML('afterend', `<h2>${message}</h2>`);
        });
    }


    return init

})();

Tracks(apiKey)

}